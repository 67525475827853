<template>
  <div class="m-2">
    <b-row>
      <b-col xl="12" md="12">
        <b-tabs content-class="pt-1" fill>
          <b-tab>
            <template #title>
              <!-- <feather-icon icon="HomeIcon" /> -->
              <span>ทั้งหมด</span>
            </template>
            <div v-for="product in product" :key="product.id" no-body>
              <div>
                <b-card>
                  <div v-if="product.type === 'product'">
                    หมายเลขคำสั่งซื้อ: {{ product.omise_transactions_id }}
                    <div style="text-align: right">
                      <b-badge
                        variant="success" style="padding: 10px;"
                        v-if="product.status === 'successful'"
                      >
                        สั่งซื้อสำเร็จ
                      </b-badge>
                      <b-badge
                        variant="danger" style="padding: 10px;"
                        v-if="product.status === 'failed'"
                      >
                        ยกเลิกแล้ว
                      </b-badge>
                    </div>
                    <hr />
                    <div class="blue box ex2">
                      <div class="coral item">
                        <span class="pink item">
                          <div>
                            <b-img :src="product.image" width="120px" />
                            <span style="font-size: 17px">
                              {{ product.name }}
                            </span>
                            <div
                              style="font-size: 12px"
                              v-html="product.detail"
                            ></div>
                          </div>
                        </span>
                      </div>
                      <div class="coral item">
                        <span class="pink item">
                          <div>
                            <table class="mt-1">
                              <tr>
                                <th align="center">ราคาต่อหน่วย</th>

                                <th align="center">รายการย่อย</th>
                              </tr>
                              <br />
                              <tr>
                                <td align="center">
                                  {{
                                    new Intl.NumberFormat("en-IN").format(
                                      product.price
                                    )
                                  }}
                                  บาท
                                </td>

                                <td align="center">
                                  จำนวน
                                  {{
                                    new Intl.NumberFormat("en-IN").format(
                                      product.quantity
                                    )
                                  }}
                                  ชิ้น
                                </td>
                              </tr>
                              <tr>
                                <td>ยอดคำสั่งซื้อทั้งหมด:</td>
                                <td>
                                  <code>
                                    {{
                                      new Intl.NumberFormat("en-IN").format(
                                        product.total_price
                                      )
                                    }}
                                    บาท
                                  </code>
                                </td>
                              </tr>
                            </table>

                            ช่องทางการชำระเงิน
                            <code>
                              {{ product.payment_options }}
                            </code>
                          </div>
                        </span>
                      </div>
                      <div class="coral item" style="width: 200px">
                        <span
                          class="pink item"
                          v-if="product.type === 'product'"
                        >
                          <div>
                            <span style="font-size: 13px">
                              <b>ที่อยู่ในการจัดส่ง</b> <br />
                              {{ product.user_address }}

                              || {{ product.phone }}<br />
                              ต. {{ product.district }} อ.
                              {{ product.amphoe }} จ. {{ product.province }} ,{{
                                product.zipcode
                              }}
                              ||
                              {{ product.detail_address }}
                              <hr />
                              <div v-if="product.status === 'successful'">
                                <b-alert
                                  variant="success"
                                  show
                                  v-if="product.delivery"
                                >
                                  <div class="alert-body">
                                    <span>{{ product.delivery_status }}</span>
                                  </div>
                                </b-alert>
                                <b-alert variant="warning" show v-else>
                                  <div class="alert-body">
                                    <span>{{ product.delivery_status }}</span>
                                  </div>
                                </b-alert>
                                {{ product.delivery }}
                              </div>
                            </span>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div v-else></div>
                </b-card>
              </div>
              <div></div>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <!-- <feather-icon icon="HomeIcon" /> -->
              <span>สั่งซื้อสำเร็จ</span>
            </template>
            <div v-for="product in product" :key="product.id">
              <b-card v-if="product.status === 'successful'">
                <div v-if="product.type === 'product'">
                  หมายเลขคำสั่งซื้อ: {{ product.omise_transactions_id }}
                  <div style="text-align: right">
                    <b-badge style="padding: 10px;"
                      variant="success"
                      v-if="product.status === 'successful'"
                    >
                      สั่งซื้อสำเร็จ
                    </b-badge>
                 
                  </div>
                  <hr />
                  <div class="blue box ex2">
                    <div class="coral item">
                      <span class="pink item">
                        <div>
                          <b-img :src="product.image" width="120px" />
                          <span style="font-size: 17px">
                            {{ product.name }}
                          </span>
                          <div
                            style="font-size: 12px"
                            v-html="product.detail"
                          ></div>
                        </div>
                      </span>
                    </div>
                    <div class="coral item">
                      <span class="pink item">
                        <div>
                          <table class="mt-1">
                            <tr>
                              <th align="center">ราคาต่อหน่วย</th>

                              <th align="center">รายการย่อย</th>
                            </tr>
                            <br />
                            <tr>
                              <td align="center">
                                {{
                                  new Intl.NumberFormat("en-IN").format(
                                    product.price
                                  )
                                }}
                                บาท
                              </td>

                              <td align="center">
                                จำนวน
                                {{
                                  new Intl.NumberFormat("en-IN").format(
                                    product.quantity
                                  )
                                }}
                                ชิ้น
                              </td>
                            </tr>
                            <tr>
                              <td>ยอดคำสั่งซื้อทั้งหมด:</td>
                              <td>
                                {{
                                  new Intl.NumberFormat("en-IN").format(
                                    product.total_price
                                  )
                                }}
                                บาท
                              </td>
                            </tr>
                          </table>
                          ช่องทางการชำระเงิน
                          <code>
                            {{ product.payment_options }}
                          </code>
                        </div>
                      </span>
                    </div>
                    <div class="coral item" style="width: 200px">
                      <span class="pink item" v-if="product.type === 'product'">
                        <div>
                          <span style="font-size: 13px">
                            <b>ที่อยู่ในการจัดส่ง</b> <br />
                            {{ product.user_address }}

                            || {{ product.phone }}<br />
                            ต. {{ product.district }} อ. {{ product.amphoe }} จ.
                            {{ product.province }} ,{{ product.zipcode }} ||
                            {{ product.detail_address }}
                            <hr />
                            <div v-if="product.status === 'successful'">
                              <b-alert variant="primary" show>
                                <div class="alert-body">
                                  <span>{{ product.delivery_status }}</span>
                                </div>
                              </b-alert>

                              {{ product.delivery }}
                            </div>
                          </span>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
                <div v-else></div>
              </b-card>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <!-- <feather-icon icon="HomeIcon" /> -->
              <span>ยกเลิกแล้ว</span>
            </template>
            <div v-for="product in product" :key="product.id">
              <b-card v-if="product.status === 'failed'">
                <div v-if="product.type === 'product'">
                  หมายเลขคำสั่งซื้อ: {{ product.omise_transactions_id }}
                  <div style="text-align: right">
                   
                    <b-badge style="padding: 10px;"
                      variant="danger"
                      v-if="product.status === 'failed'"
                    >
                      ยกเลิกแล้ว
                    </b-badge>
                  </div>
                  <hr />
                  <div class="blue box ex2">
                    <div class="coral item">
                      <span class="pink item">
                        <div>
                          <b-img :src="product.image" width="120px" />
                          <span style="font-size: 17px">
                            {{ product.name }}
                          </span>
                          <div
                            style="font-size: 12px"
                            v-html="product.detail"
                          ></div>
                        </div>
                      </span>
                    </div>

                    <div class="coral item">
                      <span class="pink item">
                        <div>
                          <table class="mt-1">
                            <tr>
                              <th align="center">ราคาต่อหน่วย</th>

                              <th align="center">รายการย่อย</th>
                            </tr>
                            <br />
                            <tr>
                              <td align="center">
                                {{
                                  new Intl.NumberFormat("en-IN").format(
                                    product.price
                                  )
                                }}
                                บาท
                              </td>

                              <td align="center">
                                จำนวน
                                {{
                                  new Intl.NumberFormat("en-IN").format(
                                    product.quantity
                                  )
                                }}
                                ชิ้น
                              </td>
                            </tr>
                            <tr>
                              <td>ยอดคำสั่งซื้อทั้งหมด:</td>
                              <td>
                                {{
                                  new Intl.NumberFormat("en-IN").format(
                                    product.total_price
                                  )
                                }}
                                บาท
                              </td>
                            </tr>
                          </table>
                          ช่องทางการชำระเงิน
                          <code>
                            {{ product.payment_options }}
                          </code>
                        </div>
                      </span>
                    </div>
                    <div class="coral item" style="width: 200px">
                      <span class="pink item" v-if="product.type === 'product'">
                        <div>
                          <span style="font-size: 13px">
                            <b>ที่อยู่ในการจัดส่ง</b> <br />
                            {{ product.user_address }}

                            || {{ product.phone }}<br />
                            ต. {{ product.district }} อ. {{ product.amphoe }} จ.
                            {{ product.province }} ,{{ product.zipcode }} ||
                            {{ product.detail_address }}
                          </span>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
                <div v-else></div>
              </b-card>
            </div>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BListGroupItem,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BCardCode,
  BFormFile,
  BImg,
  BMedia,
  BFormDatepicker,
  BCarousel,
  BCarouselSlide,
  BContainer,
  BFormText,
  BAlert,
  BFormSpinbutton,
  BLink,
  BBadge,
  BTabs,
  BTab,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";

import api from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BCardCode,
    BFormFile,
    BListGroupItem,
    BCardTitle,
    BImg,
    BMedia,
    BFormDatepicker,
    BCarousel,
    BCarouselSlide,
    BContainer,
    BFormText,
    BAlert,
    BFormSpinbutton,
    BLink,
    BBadge,
    BTabs,
    BTab,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      product: [],
      source: this.$route.query.source,
    };
  },
  watch: {},
  mounted() {
    this.get_transaction();
    if (this.$route.query.source) {
      this.$swal({
        title: "ชำระเงินสำเร็จ!",
        text: "สร้างรายการชำระสินค้าสำเร็จ!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    }
  },
  created() {},
  computed: {},
  methods: {
    get_transaction() {
      const params = {
        source: this.$route.query.source,
      };

      api.post("/get_transaction", params).then((response) => {
        this.product = response.data.sales_order_list;
        console.log(response.data);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
th,
td {
  padding-right: 15px;
  text-align: center;
}

.ex2 {
  display: flex;
  justify-content: space-between;
}
</style>
